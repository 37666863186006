import React from 'react';

import { styled } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import { styles } from 'theme/table-style';

const CellContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const TextCell = ({
  loading,
  keyValue,
  description,
  textVariant,
  capitalize,
  uppercase,
}) => {
  if (loading && !keyValue) {
    return <TextCellSkeleton />;
  }

  if (!loading && !keyValue) {
    return <div>-</div>;
  }

  const valueStyle = capitalize
    ? { textTransform: 'capitalize' }
    : uppercase
      ? { textTransform: 'uppercase' }
      : {};

  return (
    <CellContainer>
      <TypographyEllipsis noWrap component="p" variant={textVariant} sx={valueStyle}>
        {keyValue}
      </TypographyEllipsis>
      {description && (
        <TypographyEllipsis noWrap component="p" variant="subtitleLight">
          {description}
        </TypographyEllipsis>
      )}
    </CellContainer>
  );
};

TextCell.propTypes = {
  keyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  textVariant: PropTypes.string,
  capitalize: PropTypes.bool,
  uppercase: PropTypes.bool,
};

TextCell.defaultProps = {
  textVariant: 'subtitle',
  capitalize: false,
  uppercase: false,
};

export default withStyles(styles)(TextCell);
